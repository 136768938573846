/* eslint-disable camelcase */
import '../scss/mortar.scss'

import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap'
import '@coreui/coreui'

import 'bootstrap-select'

function uploadData (formdata, url) {
  $.ajax({
    url,
    data: formdata,
    dataType: 'text', // what to expect back from the PHP script
    cache: false,
    contentType: false,
    processData: false,
    type: 'post',
    success: function (r) {
      document.location.href = '/statements'
    },
    error: function (e) {
      console.log(e)
    }
  })
}

$(document).ready(function () {
  // bank account description click
  $('.bank-account-description').click(function () {
    $(this).next().slideToggle()
    return false
  })
  const transition_speed = 'fast'
  // bank account relation updater click

  $('[data-toggle="tooltip"]').tooltip()

  $(document).on('click', '.bank-account-relation-updater', function () {
    const $this = $(this)
    const account_id = $this.data('accountid')
    const submit_button = $('<button type="submit" class="btn btn-pill btn-warning btn-sm" style="display: none;">save</button>')
    const cancel_button = $('<a class="btn btn-pill btn-danger btn-sm bank-account-relation-updater-cancel" style="margin-left: 6px; display: none;" href="#" data-accountid="' + account_id + '">cancel</a>')
    $this.fadeOut(transition_speed, function () {
      $this.after(cancel_button)
      $this.after(submit_button)
      $this.remove()
      $('#invoice_select_' + account_id).fadeIn(transition_speed, function () {
        submit_button.fadeIn(transition_speed)
        cancel_button.fadeIn(transition_speed)
      })
    })
    return false
  })
  $(document).on('click', '.bank-account-relation-updater-cancel', function () {
    const $this = $(this)
    const account_id = $this.data('accountid')
    const update_button = $('<a class="btn btn-pill btn-info btn-sm bank-account-relation-updater" style="display: none;" href="#" data-accountid="' + account_id + '">update</a>')
    $this.prev().fadeOut(transition_speed)
    $this.fadeOut(transition_speed, function () {
      $this.after(update_button)
      $this.prev().remove()
      $this.remove()
      $('#invoice_select_' + account_id).fadeOut(transition_speed, function () {
        update_button.fadeIn(transition_speed)
      })
    })
    return false
  })
})

window.uploadStatementPage = () => {
  $(document).ready(function () {
    const $fileInput = $('#id_file_name')
    const $fileForm = $('#uploadForm')
    const $uploadh1 = $('#uploadh1')
    const $uploadIcon = $('.upload-area .fa-file-upload')
    const $spinner = $('.upload-area .fa-spinner')
    const csrfInputName = 'csrfmiddlewaretoken'
    const $submit = $('#upload-submit')
    const $csrfInput = $(`[name='${csrfInputName}']`)

    // preventing page from redirecting
    $('html').on('dragover', function (e) {
      e.preventDefault()
      e.stopPropagation()
      $uploadh1.text('Drag here')
    })

    $('html').on('drop', function (e) { e.preventDefault(); e.stopPropagation() })

    // Drag enter
    $('.upload-area').on('dragenter', function (e) {
      e.stopPropagation()
      e.preventDefault()
      $uploadh1.text('Drop')
    })

    // Drag over
    $('.upload-area').on('dragover', function (e) {
      e.stopPropagation()
      e.preventDefault()
      $uploadh1.text('Drop')
    })

    // Drop
    $('.upload-area').on('drop', function (e) {
      e.stopPropagation()
      e.preventDefault()
      $uploadh1.hide()
      $uploadIcon.hide()
      $spinner.show()

      var file = e.originalEvent.dataTransfer.files
      var fd = new FormData()
      fd.append('file_name', file[0])
      fd.append(csrfInputName, $csrfInput.val())
      uploadData(fd, '/statements/upload')
      // console.log(fd.values())
    })

    // Open file selector on div click
    $('#uploadfile').click(function () {
      $fileInput.click()
    })

    // file selected
    $fileInput.change(function () {
      $submit.prop('disabled', false)
      $fileForm.submit()
    })
  })
}

window.tenancyForm = () => {

}

window.bankAccountPage = () => {
  $(document).ready(() => {
    $("select[name^='invoice_select']").selectpicker({
      liveSearch: true,
      size: 5
    })
  })
}
